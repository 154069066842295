<template>
  <div class="scroll-container" ref="scrollContainer" @scroll="onScroll">
    <div class="scroll-content" ref="scrollContent">
      <!-- 显示文本内容 -->
      {{ textContent }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    currentTime: {
      type: Number,
      required: true,
    },
    audioDuration: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      textContent: '', // 用于存储加载的文本内容
      textFileUrl: '', // 动态获取的文本文件的 URL
      isScrolling: false, // 标志是否在手动滚动中
    };
  },
  watch: {
    currentTime(newValue) {
      if (!this.isScrolling) {
        this.updateScrollPosition(newValue);
      }
    },
  },
  async mounted() {
    await this.fetchTextFileUrl(); // 获取文件 URL
    this.loadTextContent(); // 加载文本内容
  },
  methods: {
    async fetchTextFileUrl() {
      try {
        const id = this.$route.params.id; // 从路由中获取id参数
        const response = await axios.get(`/api/api/upload/file?${id}`);
        
        const textFileDownloadUri = response.data.data.textFileDownloadUri;
        const relativePath = textFileDownloadUri.split('/uploads/')[1];
        this.textFileUrl = `/api/uploads/${relativePath}`;

        console.log('Text file URL:', this.textFileUrl);
      } catch (error) {
        console.error('获取文本文件 URL 失败:', error);
      }
    },
    async loadTextContent() {
      try {
        if (this.textFileUrl) {
          console.log('Fetching text content from:', this.textFileUrl);
          const response = await axios.get(this.textFileUrl);
          this.textContent = response.data;
        } else {
          console.error('文本文件 URL 未定义');
        }
      } catch (error) {
        console.error('加载文本内容失败:', error);
      }
    },
    updateScrollPosition(currentTime) {
      const scrollContent = this.$refs.scrollContent;
      const scrollContainer = this.$refs.scrollContainer;
      const containerHeight = scrollContainer.clientHeight;
      const contentHeight = scrollContent.scrollHeight;

      const totalHeight = contentHeight;
      const scrollPercentage = currentTime / this.audioDuration;

      const scrollPosition = scrollPercentage * totalHeight - (containerHeight / 2);
      scrollContainer.scrollTop = scrollPosition;
    },
    
    onScroll() {
    const scrollContainer = this.$refs.scrollContainer;
    const scrollContent = this.$refs.scrollContent;
    const containerHeight = scrollContainer.clientHeight;
    const contentHeight = scrollContent.scrollHeight;

    const totalHeight = contentHeight;

    // 计算当前滚动位置占总高度的百分比
    const scrollPercentage = (scrollContainer.scrollTop + (containerHeight / 2)) / totalHeight;

    // 计算对应的音频时间
    const newTime = scrollPercentage * this.audioDuration;

    // 发射事件通知父组件更新音频当前时间
    this.$emit('update:currentTime', newTime);

    // 确保音频从新位置开始播放
    this.$emit('play-from-new-time', newTime);
  },
},
  }
</script>
<style scoped>
.scroll-container {
  height: 100%;
  overflow-y: scroll;
}

.scroll-content {
  white-space: pre-wrap; /* 保持文本格式 */
}
</style>

<style scoped>
.scroll-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll; /* 允许垂直滚动 */
}

.scroll-content {
  white-space: pre-wrap;
  font-size: 18px; /* 调整字体大小以适应手机屏幕 */
  line-height: 1.4;
  padding: 0 10px; /* 设置左右留白 */
  margin-top: calc(20vh - 1em); /* 留出上半页的空白 */
  margin-bottom: calc(23vh - 1em); /* 留出下半页的空白 */
  height: auto; /* 自动调整高度以显示所有文字 */
}
</style>