
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    audioUrl: localStorage.getItem('audioUrl') || '',
    textUrl: localStorage.getItem('textUrl') || '',
  },
  mutations: {
    setAudioUrl(state, url) {
      state.audioUrl = url;
      localStorage.setItem('audioUrl', url);
    },
    setTextUrl(state, url) {
      state.textUrl = url;
      localStorage.setItem('textUrl', url);
    },
  },
  actions: {
    updateAudioUrl({ commit }, url) {
      commit('setAudioUrl', url);
    },
    updateTextUrl({ commit }, url) {
      commit('setTextUrl', url);
    },
  },
});