import Vue from 'vue';
import Router from 'vue-router';
import UploadPage from '@/components/UploadPage.vue'; // 引入上传页面
import AudioPlayer from '@/components/AudioPlayer.vue'; // 引入播放页面
import PaymentPage from '@/components/PaymentPage.vue'; // 引入支付页面
import UrlsDisplay from '@/components/UrlsDisplay.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/one/:id',
      name: 'PaymentPage',
      component: PaymentPage,
    },
    {
      path: '/upload',
      name: 'UploadPage',
      component: UploadPage,
    },
    {
      path: '/play/:id',
      name: 'AudioPlayer',
      component: AudioPlayer,
    },
    {
      path: '/p',
      component: UrlsDisplay,
    },
  ],
});