<template>
  <div class="container">
    <!-- 音乐播放器 -->
    <div class="player">
      <audio ref="audioPlayer" controls @loadedmetadata="onMetadataLoaded">
        <source :src="audioSrc" type="audio/mp3">
        Your browser does not support the audio element.
      </audio>
    </div>

    <!-- 背景文字滚动 -->
    <div class="background-text" ref="backgroundText">
      <div class="text-content">
        <p v-for="(line, index) in textLines" :key="index">{{ line }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audioSrc: '/api/uploads/%E6%B8%85%E4%BB%A3%E8%A7%A3%E8%AF%B4%E8%AF%8D_your-audio.mp3', // 替换为你的音频文件 URL
      textLines: [
        '欢迎来访爱听音乐网 www.2t58.com',
        '',
        '不再犹豫 - BEYOND',
        '词：梁美薇',
        '曲：黄家驹',
        '',
        '无聊望见了犹豫',
        '达到理想不太易',
        '即使有信心斗志却抑止',
        '谁人定我去或留',
        '定我心中的宇宙',
        '只想靠两手 向理想挥手',
        '',
        '问句天几高 心中志比天更高',
        '自信打不死的心态活到老',
        '',
        'Wo oh 我有我心底故事',
        '亲手写上每段得失乐与悲与梦儿',
        'Wo oh 纵有创伤不退避',
        '梦想有日达成找到心底梦想的世界',
        '终可见',
        '',
        '谁人没试过犹豫',
        '达到理想不太易',
        '即使有信心斗志却抑止',
        '谁人定我去或留',
        '定我心中的宇宙',
        '只想靠两手 向理想挥手',
        '',
        '问句天几高 心中志比天更高',
        '自信打不死的心态活到老',
        '',
        'Wo oh 我有我心底故事',
        '亲手写上每段得失乐与悲与梦儿',
        'Wo oh 纵有创伤不退避',
        '梦想有日达成找到心底梦想的世界',
        '终可见',
        '',
        'Wo oh',
        '亲手写上每段得失乐与悲与梦儿',
        'Wo oh',
        '梦想有日达成找到心底梦想的世界',
        '终可见'
      ],
      scrollSpeed: 0, // 滚动速度
      textHeight: 0, // 背景文字高度
    };
  },
  methods: {
    onMetadataLoaded() {
      // 当音频元数据加载完成后计算滚动速度
      this.calculateScrollSpeed();
      this.startScrolling();
    },
    calculateScrollSpeed() {
      // 计算滚动速度
      const textContent = this.$refs.backgroundText;
      this.textHeight = textContent.scrollHeight;
      const audioDuration = this.$refs.audioPlayer.duration;

      if (audioDuration) {
        this.scrollSpeed = this.textHeight / audioDuration;
      }
    },
    startScrolling() {
      // 启动背景文字滚动
      const textContent = this.$refs.textContent;
      let currentPosition = 0;

      const updateScroll = () => {
        currentPosition += this.scrollSpeed / 60; // 60 FPS
        if (currentPosition >= this.textHeight) {
          currentPosition = 0; // 循环滚动
        }
        textContent.style.transform = `translateY(-${currentPosition}px)`;
        requestAnimationFrame(updateScroll);
      };

      updateScroll();
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.player {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 10;
}

.background-text {
  position: absolute;
  top: 50px; /* 给播放器下方留出空隙 */
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  white-space: nowrap;
}

.text-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
</style>
