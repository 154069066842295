<template>
  <div class="audio-player-container">
    <!-- 滚动的文字背景 -->
    <ScrollingText
      class="scrolling-text"
      :currentTime.sync="currentTime"
      :audioDuration="audioDuration"
    />

    <!-- 漂浮在文字上方的音频播放器 -->
    <div class="audio-player">
      <button class="play-button" @click="togglePlay">{{ isPlaying ? '暂停' : '播放音频' }}</button>
      <div class="progress-container">
        <input
          type="range"
          min="0"
          :max="audioDuration"
          v-model="currentTime"
          @input="seekAudio"
          class="progress-bar"
        />
        <span class="time">{{ formatTime(currentTime) }} / {{ formatTime(audioDuration) }}</span>
      </div>
      <audio ref="audio" :src="audioSource" @timeupdate="updateTime" @loadedmetadata="loadMetadata"></audio>
    </div>
  </div>
</template>

<script>
import ScrollingText from '@/components/ScrollingText.vue';
import axios from 'axios';

export default {
  components: {
    ScrollingText,
  },
  data() {
    return {
      isPlaying: false,
      currentTime: 0,
      audioDuration: 0,
      audioSource: '', // 初始音频 URL 为空
      isSeeking: false, // 标记是否正在拖动进度条
    };
  },
  async created() {
    try {
      const id = this.$route.params.id; // 从路由中获取id参数
    const response = await axios.get(`/api/api/upload/file?${id}`);
    console.log('Request URL:', response);
      const mp3FileUrl = response.data.data.mp3FileDownloadUri;
      const relativePath = mp3FileUrl.split('/uploads/')[1];
      this.audioSource = `/api/uploads/${relativePath}`;
    } catch (error) {
      console.error('获取音频 URL 失败:', error);
    }
  },
  methods: {
    togglePlay() {
      const audio = this.$refs.audio;
      if (this.isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    updateTime() {
      if (!this.isSeeking) {
        this.currentTime = this.$refs.audio.currentTime;
      }
    },
    seekAudio() {
      this.$refs.audio.currentTime = this.currentTime;
    },
    loadMetadata() {
      this.audioDuration = this.$refs.audio.duration;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    handleScroll(newTime) {
      // 计算时间差
      const timeDifference = Math.abs(this.$refs.audio.currentTime - newTime);

      if (timeDifference >= 3) { // 如果拖动时间差大于3秒
        this.isSeeking = true;
        this.$refs.audio.currentTime = newTime;
        this.isSeeking = false;
      }
    },
  },
  watch: {
    currentTime(newValue) {
      if (!this.isSeeking) {
        this.handleScroll(newValue); // 仅在拖动进度条时更新播放位置
      }
    },
  },
};
</script>

<style scoped>
.audio-player-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto; /* 允许垂直滚动 */
  -webkit-overflow-scrolling: touch; /* 启用惯性滚动，适用于iOS设备 */
}

.scrolling-text {
  padding-top: 80px; /* 为了让顶部有空隙 */
  padding-bottom: 20px; /* 底部空隙，避免被音频播放器遮挡 */
  overflow-y: auto; /* 确保文字区域可以滚动 */
  max-height: calc(100vh - 100px); /* 动态计算内容高度，避免音频播放器遮挡 */
}

.audio-player {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明黑色背景 */
  padding: 20px;
  width: 90%;
  max-width: 360px;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* 添加阴影 */
}

.play-button {
  padding: 10px 20px;
  border-radius: 25px;
  background: linear-gradient(135deg, #42b983, #2c8e5b); /* 渐变背景 */
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase; /* 全部字母大写 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 添加按钮阴影 */
  transition: transform 0.2s ease; /* 添加轻微的缩放动画 */
}

.play-button:hover {
  transform: scale(1.05); /* 鼠标悬停时放大 */
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.progress-bar {
  width: 100%;
  appearance: none;
  background-color: #ddd;
  height: 8px; /* 调整进度条高度 */
  border-radius: 5px;
  margin-bottom: 10px; /* 调整间距 */
  overflow: hidden; /* 确保滑块在圆角内 */
}

.progress-bar::-webkit-slider-runnable-track {
  background: linear-gradient(135deg, #42b983, #2c8e5b); /* 进度条背景渐变 */
  height: 100%;
  border-radius: 5px;
}

.progress-bar::-webkit-slider-thumb {
  appearance: none;
  width: 16px; /* 调整滑块大小 */
  height: 16px;
  background-color: #42b983;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 添加滑块阴影 */
  transition: background-color 0.3s ease; /* 添加颜色过渡 */
}

.progress-bar::-moz-range-thumb {
  width: 16px; /* 调整滑块大小 */
  height: 16px;
  background-color: #42b983;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 添加滑块阴影 */
  transition: background-color 0.3s ease; /* 添加颜色过渡 */
}

.time {
  font-size: 14px; /* 调整字体大小 */
  color: #fff;
  font-weight: bold;
}
</style>