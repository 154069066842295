<template>
  <div class="upload-container">
    <h2>上传音频和文字</h2>

    <!-- 节目名称输入 -->
    <div class="upload-section">
      <label for="programName">节目名称：</label>
      <input type="text" id="programName" v-model="programName" placeholder="请输入节目名称" />
      <el-button type="primary" icon="el-icon-search" @click="searchProgram">搜索</el-button>
    </div>

    <!-- 上传音频文件 -->
    <div class="upload-section">
      <label for="audioFile">上传音频：</label>
      <input type="file" id="audioFile" accept="audio/*" @change="onAudioChange" />
    </div>

    <!-- 上传文字文件 -->
    <div class="upload-section">
      <label for="textFile">上传文字：</label>
      <input type="file" id="textFile" accept=".txt" @change="onTextChange" />
    </div>

    <el-button type="primary" @click="handleUpload">确认上传</el-button>

    <!-- 显示节目列表 -->
    <el-table :data="programList" style="width: 100%; margin-top: 20px;">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="programName" label="节目名称"></el-table-column>
      <el-table-column >
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="generateQRCode(scope.row.id)">生成二维码</el-button>
        </template>
      </el-table-column>

      <!-- 删除按钮列 -->
      <el-table-column label="操作" align="right">
        <template v-slot="scope">
          <el-button type="danger" size="mini" @click="deleteProgram(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页条 -->
    <el-pagination
      layout="prev, pager, next"
      :total="totalPrograms"
      @current-change="handlePageChange"
      style="margin-top: 20px;"
    />

    <!-- 二维码展示弹窗 -->
    <el-dialog :visible.sync="dialogVisible" title="生成的二维码" width="30%">
      <div class="qrcode-container" v-if="qrCodeDataUrl">
        <img :src="qrCodeDataUrl" alt="QR Code" style="width: 100%; height: auto;" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import axios from 'axios';
import { Message } from 'element-ui';
import QRCode from 'qrcode';

export default {
  data() {
    return {
      programName: '',
      selectedAudio: null,
      selectedText: null,
      programList: [], // 用于存储节目列表
      totalPrograms: 0, // 节目总数，用于分页
      currentPage: 1, // 当前页码
      dialogVisible: false, // 控制二维码弹窗显示
      qrCodeDataUrl: '', // 存储生成的二维码的 Data URL
    };
  },
  methods: {
    onAudioChange(event) {
      this.selectedAudio = event.target.files[0];
    },
    onTextChange(event) {
      this.selectedText = event.target.files[0];
    },
    async handleUpload() {
      if (this.programName && this.selectedAudio && this.selectedText) {
        const formData = new FormData();
        formData.append('programName', this.programName);
        formData.append('mp3File', this.selectedAudio);
        formData.append('textFile', this.selectedText);

        try {
          await axios.post('/api/api/upload/files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          Message.success('上传成功');
          this.fetchPrograms(); // 上传成功后重新获取节目列表
        } catch (error) {
          console.error('上传失败:', error.response.data);
          Message.error('上传失败，请重试。');
        }
      } else {
        Message.warning('请填写节目名称并上传音频和文字文件');
      }
    },
    async fetchPrograms() {
      try {
        const response = await axios.get(`/api/api/upload/page?page=${this.currentPage}&programName=${this.programName}`);
        if (response.data && response.data.code === 1) {
          this.programList = response.data.data.rows;
          this.totalPrograms = response.data.data.total;
        }
      } catch (error) {
        console.error('获取节目列表失败:', error);
        Message.error('获取节目列表失败');
      }
    },
    async deleteProgram(programId) {
      try {
        await axios.delete(`/api/api/upload/delete?id=${programId}`);
        Message.success('删除成功');
        this.fetchPrograms(); // 删除成功后重新获取节目列表
      } catch (error) {
        console.error('删除失败:', error);
        Message.error('删除失败，请重试。');
      }
    },
    async generateQRCode(programId) {
      const url = `http://192.168.101.40:7070/#/one/id=${programId}`;
      try {
        this.qrCodeDataUrl = await QRCode.toDataURL(url);
        this.dialogVisible = true;
      } catch (error) {
        console.error('生成二维码失败:', error);
        Message.error('生成二维码失败');
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchPrograms();
    },
    searchProgram() {
      this.fetchPrograms(); // 搜索后重新获取节目列表
    },
  },
  mounted() {
    this.fetchPrograms(); // 组件加载时获取节目列表
  },
};

</script>
<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.upload-section {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

label {
  font-size: 18px;
  flex-basis: 150px;
}

input[type="file"],
input[type="text"] {
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.el-button {
  margin-left: 10px;
  background-color: #409EFF;
  color: white;
}

.el-button:hover {
  background-color: #66b1ff;
  color: white;
}

.el-table {
  margin-top: 20px;
}
</style>
