<template>
  <div class="payment-container">
    <h2>支付页面</h2>
    <p>您需要支付以继续观看。</p>

    <!-- 手机号输入框 -->
    <div class="input-group">
      <label for="phone">手机号：</label>
      <input type="text" id="phone" v-model="phoneNumber" placeholder="请输入手机号" />
    </div>

    <div class="input-group">
      <label for="amount">金额：</label>
      <input type="text" id="amount" v-model="paymentAmount" readonly />
    </div>

    <button @click="handlePayment">立即支付</button>
  </div>
</template>

<script>
/* global WeixinJSBridge */
import axios from 'axios';
import md5 from 'md5';

export default {
  data() {
    return {
      phoneNumber: '', // 用户手机号
      paymentAmount: '0.1', // 支付金额
      openid: '', // 用户的openid
    };
  },
  mounted() {
    this.getWeChatCode(); // 页面加载时获取微信授权码
  },
  methods: {
    async getWeChatCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        // 如果URL中没有code，重定向到微信授权页面
        const appId = 'wx39765638dfe84c25'; // 你的公众号的 appId
        const redirectUri = encodeURIComponent(window.location.href); // 当前页面的 URL
        const state = 'some_random_state'; // 任意字符串，微信会原样返回

        const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
        // this.getOpenId("123456");
        // return;
        window.location.href = authUrl;
      } else {
      //  有code时，获取openid
        this.getOpenId(code);
      }
    },
    async getOpenId(code) {
      try {
        // const appid = 'wx39765638dfe84c25';
        // const appsecret = "aec3ec9c3939eba4fd7f3f8633cefc77";
        const response = await axios.get('https://we.qigema.tech/djyc_ZXQP/index.php/Login/testlogin?code='+code);
        this.openid = response.data.openid;
      } catch (error) {
        console.error('获取 openid 失败:', error);
        alert('获取 openid 失败，请稍后重试。'+error);
      }
    },
    async handlePayment() {
      if (!this.phoneNumber) {
        alert('请输入手机号');
        return;
      }

      if (!this.openid) {
        alert('获取用户信息失败，请稍后重试。'+this.openid);
        return;
      }

      try {
      // 调用微信支付
        // this.gotopay();
        this.processPayment();
        // const response = await axios.post('https://1d8c9c97.r21.cpolar.top/pay/checkPhoneNumber', {
        //   phone: this.phoneNumber,
        // });

        // if (response.data.exists) {
        //   window.location.href = 'https://we.qigema.tech/dist/#/play/id=62';
        // } else {
        //   this.processPayment();
        // }
      } catch (error) {
        console.error('检查手机号失败:', error);
        alert('检查手机号失败，请稍后重试。'+error);
      }
    },
    // async gotopay() {
    //   try {
    //     // 获取支付参数
    //     const response = await axios.post('https://we.qigema.tech/djyc_ZXQP/index.php/Order/getconfig', {
    //       openid: this.openid, // 从登录状态中获取
    //       order_id: this.generateOrderNumber(),
    //       total_fee: parseInt(this.paymentAmount * 100) // 以分为单位
    //     });

    //     const payParams = response.data;
    //     alert(JSON.stringify(payParams))
    //     // 配置微信 JS SDK
    //     wx.config({
    //       debug: false,
    //       appId: payParams.appId,
    //       timestamp: payParams.timeStamp,
    //       nonceStr: payParams.nonceStr,
    //       signature: payParams.paySign,
    //       jsApiList: ['chooseWXPay']
    //     });

    //     wx.ready(() => {
    //       // 发起支付请求
    //       wx.chooseWXPay({
    //         timestamp: payParams.timeStamp,
    //         nonceStr: payParams.nonceStr,
    //         package: payParams.package,
    //         signType: 'SHA1',
    //         paySign: payParams.paySign,
    //         success: (res) => {
    //           alert('支付成功');
    //         },
    //         fail: (res) => {
    //           alert('支付失败');
    //         }
    //       });
    //     });

    //     wx.error((res) => {
    //       alert('微信 SDK 配置错误');
    //     });

    //   } catch (error) {
    //     console.error('支付请求失败:', error);
    //   }
    // },
    async processPayment() {
      try {
        // const spAppid = 'wx39765638dfe84c25'; // 使用你的服务商应用ID
        // const spMchid = '1647428351'; // 使用你的商户号
        // const description = '测试商品'; // 商品描述
        // const outTradeNo = this.generateOrderNumber(); // 生成商户订单号
        // const notifyUrl = 'http://541dd4b1.r21.cpolar.top'; // 异步通知URL
        // const amount = {
        //   total: parseInt(this.paymentAmount * 100), // 微信支付单位为分
        //   currency: 'CNY',
        // };

        // const payer = {
        //   openid: this.openid, // 使用前面获取的用户openId
        // };

        // 请求微信支付API，获取prepay_id
        const result = await axios.get('https://we.qigema.tech/djyc_ZXQP/index.php/Test/pay?openid='+this.openid);

        // const { packages } = result.data;
        // alert(JSON.stringify(result.data))
        // 调用微信JSAPI支付
        this.invokeWeChatPay(JSON.parse(result.data));
      } catch (error) {
        console.error('支付请求失败:', error);
        alert('支付请求失败，请稍后重试。'+error);
      }
    },
    invokeWeChatPay(datas) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', () => this.invokeWeChatPay(datas.prepayId), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', () => this.invokeWeChatPay(datas.prepayId));
          document.attachEvent('onWeixinJSBridgeReady', () => this.invokeWeChatPay(datas.prepayId));
        }
      } else {
        // const timeStamp = String(Math.floor(Date.now() / 1000));
        // const nonceStr = this.generateNonceStr();
        // const paySign = this.generatePaySign(prepayId, timeStamp, nonceStr);
        alert(datas)
        //alert(datas.appId)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: datas.appId, // 公众号ID，由微信支付提供
            timeStamp:datas.timeStamp, // 时间戳，当前的时间
            nonceStr:datas.nonceStr, // 随机字符串
            package: datas.package, // 预支付交易会话标识
            signType: 'MD5', // 签名方式
            paySign:datas.paySign, // 签名
            total_fee:1000
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 支付成功
              
              window.location.href = 'http://192.168.101.40:7070/#/play';
            } else {
              alert('支付失败：' + res.err_msg);
            }
          },
        );
      }
    },
    generateOrderNumber() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
      return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
    },
    generateNonceStr() {
      return Math.random().toString(36).substr(2, 15);
    },
    generatePaySign(prepayId, timeStamp, nonceStr) {
      const stringA = `appId=wx39765638dfe84c25&nonceStr=${nonceStr}&package=prepay_id=${prepayId}&signType=MD5&timeStamp=${timeStamp}`;
      const stringSignTemp = `${stringA}&key=aec3ec9c3939eba4fd7f3f8633cefc77`; // 使用你的API密钥
      return md5(stringSignTemp).toUpperCase();
    },
  },
};
</script>


<style scoped>
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333333;
}

p {
  font-size: 16px;
  color: #666666;
  margin-bottom: 30px;
}

.input-group {
  width: 100%;
  margin-bottom: 20px;
}

label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333333;
  display: block;
}

input[type="text"] {
  font-size: 18px;
  padding: 10px;
  width: calc(100% - 20px);
  border: 1px solid #dddddd;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background-color: #42b983;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 200px;
}

button:hover {
  background-color: #36a773;
}

@media (max-width: 480px) {
  h2 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }

  button {
    padding: 12px 20px;
    font-size: 16px;
    max-width: 100%;
  }
}
</style>